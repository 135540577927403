//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { feature } from "../services/constants";
export default {
  name: "feature",
  data: () => ({
    width: 420,
    text_fontsize: 30,
  }),
  methods: {
    onClickMenu(link) {
      // console.log(link);
      this.$router.push(link).catch((err) => {});
    },
  },
  async beforeCreate() {
    this.$store.state.page_reload = false;

    // alert(localStorage.getItem("language"))
    // console.log("language login : " + localStorage.getItem(feature.LANGUAGE))
  },
  async mounted() {
    this.$hideLoader();

  },
};
